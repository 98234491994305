<template>
  <div>
    <template v-if="!loading">
      <div class="row mb-4">
        <div class="col-md-12">
          <a href="#" class="btn btn-info float-end btn-sm py-2" @click.prevent="" v-if="details.pending_payment">Pending Payment</a>
          <div class="dropdown">
            <button
              class="btn btn-primary dropdown-toggle btn-sm py-2 float-end me-3"
              type="button"
              id="dropdownMenuButton-section"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Actions
            </button>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton-section"
            >
            <a
                href="#"
                class="dropdown-item"
                @click.prevent="cancelPayment"
                v-if="details.pending_payment"
                >Cancel Payment</a
              >
              <a
                href="#"
                class="dropdown-item"
                @click.prevent="adminSetStatus('unpublished')"
                v-if="details.status == 'active'"
                >Unpublish</a
              >
              <a
                href="#"
                class="dropdown-item"
                @click.prevent="adminSetStatus('active')"
                v-if="details.status == 'unpublished'"
                >Publish</a
              >
              <a
                href="#"
                class="dropdown-item"
                @click.prevent="adminSetStatus('active')"
                v-if="details.status == 'pending' || details.status == 'denied'"
                >Activate</a
              >
              <a
                href="#"
                class="dropdown-item"
                @click.prevent="show_promote = true"
                v-if="details.status == 'pending' || details.status == 'active'"
                >Promote</a
              >
              <a
                href="#"
                class="dropdown-item"
                @click.prevent="adminSetStatus('denied')"
                v-if="details.status == 'pending' || details.status == 'active'"
                >Deny</a
              >
              <router-link :to="{ name: 'admin.properties.edit' }" class="dropdown-item">Edit</router-link>
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="confirmDelete"
                >Delete</a
              >
            </div>
          </div>
          <h2>{{ details.name }}</h2>
          <h5>
            <span
              :class="`btn btn-${
                details.status == 'pending'
                  ? 'warning'
                  : details.status == 'active'
                  ? 'success'
                  : 'danger'
              } me-5`"
              >status: {{ details.status }}</span
            >
            <span class="me-5"
              ><i class="lni lni-dollar me-2"></i><small>KES</small>
              {{ details.amount }}</span
            >
            <span class="me-5"
              ><i class="lni lni-map-marker me-2"></i
              >{{ details.region }}</span
            >
            <span class="me-5"
              ><i class="lni lni-hourglass me-2"></i>{{ details.date }}</span
            >
            <span class="me-5 badge badge-success" v-if="details.paid"
              ><i class="lni lni-bullhorn me-2"></i>Promoted</span
            >
          </h5>
        </div>
      </div>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="preview-tab"
            data-bs-toggle="tab"
            data-bs-target="#preview"
            type="button"
            role="tab"
            aria-controls="preview"
            aria-selected="false"
          >
            Preview
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="statistics-tab"
            data-bs-toggle="tab"
            data-bs-target="#statistics"
            type="button"
            role="tab"
            aria-controls="statistics"
            aria-selected="true"
          >
            Statistics
          </button>
        </li>
      </ul>
      <div class="tab-content bg-white" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="preview"
          role="tabpanel"
          aria-labelledby="preview-tab"
        >
          <preview :property="details" />
        </div>
        <div
          class="tab-pane fade"
          id="statistics"
          role="tabpanel"
          aria-labelledby="statistics-tab"
        >
          <statistics :slug="details.slug" />
        </div>
      </div>
    </template>
    <loading v-if="loading" />
    <modal :show="show_promote" @close="show_promote = false">
      <template slot="header">Select Promotion Duration</template>
      <form @submit.prevent="promote">
        <div class="form-group">
          <label>Promote for:</label>
          <select class="form-control">
            <option :value="i" v-for="i in 7" :key="`promote-weeks-${i}`">{{ i }} Weeks</option>
          </select>
        </div>
        <div class="form-group">
          <button class="btn btn-primary">Save</button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import Preview from "./components/Preview.vue";
import Statistics from "./components/Statistics.vue";

export default {
  components: {
    Preview,
    Statistics,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      details: {},
      loading: true,
      show_promote: false,
      promote_weeks: 1
    };
  },

  methods: {
    confirmDelete() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to undo this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete property!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletePost()
        }
      })
    },

    fetch() {
      this.loading = true;
      this.axios
        .get(`/api/v1/admin/properties/${this.$route.params.id}`)
        .then((response) => {
          this.details = response.data.details;
          this.loading = false;
        });
    },
    
    deletePost() {
      this.axios.delete(`/api/v1/admin/properties/${this.$route.params.id}`).then(() => {
        this.$router.push({ name: 'admin.properties.index' })
      })
    },

    adminSetStatus(status) {
      this.axios.put(`/api/v1/admin/properties/${this.$route.params.id}`, { status: status }).then(() => {
        this.fetch()
      })
    },

    promote() {
      this.$axios.post(`/api/v1/admin/properties/${this.$route.params.id}/promote`, {weeks: this.promote_weeks}).then(() => {
        this.show_promote = false
        this.$swal({
        title: 'Success',
        text: "The post has been promoted.",
        icon: 'success',
      })
        this.fetch()
      })
    },

    cancelPayment() {
      this.axios
        .put(`/api/v1/my-listings/${this.details.id}/cancel-promote`)
        .then(() => {
          this.fetch()
        })
    }
  },
};
</script>
