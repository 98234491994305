<template>
  <div>
    <!-- ============================ Hero Banner  Start================================== -->
      <div class="container-fluid pt-4">
        <div class="featured_slick_gallery gray">
          <hooper :settings="hooperSettings">
            <slide class="px-2" v-for="(image, i) in property.images" :key="`image-${i}`" :index="`image-${i}`">
              <a href="#" class="mfp-gallery" @click.prevent="openPopup(image)"
                ><img :src="image" class="img-fluid mx-auto" :alt="image" 
              /></a>
            </slide>
            <hooper-navigation slot="hooper-addons"></hooper-navigation>
          </hooper>
          <a href="JavaScript:Void(0);" id="view-photos" class="btn-view-pic top">View photos</a>
        </div>
      </div>
      <!-- ============================ Hero Banner End ================================== -->

      <!-- ============================ Property Header Info Start================================== -->
      <section class="gray-simple rtl p-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-11 col-md-12">
              <div class="property_block_wrap style-3">
                <div class="pbw-flex-1">
                  <div class="pbw-flex-thumb p-2">
                    <img
                      :src="property.image"
                      class="img-fluid"
                      :alt="property.name"
                      width="160px"
                    />
                  </div>
                </div>
                <div class="pbw-flex">
                  <div class="prt-detail-title-desc">
                    <span class="prt-types sale">For {{ property.type }}</span>
                    <h3>{{ property.name }}</h3>
                    <span
                      ><i class="lni lni-map-marker"></i> {{ property.region }}</span
                    >
                    <h3 class="prt-price-fix"><small>KES</small>{{ property.amount }}</h3>
                    <div class="list-fx-features">
                      <div class="listing-card-info-icon" v-for="(quality, i) in property.qualities" :key="`quality-${i}`">
                        <div class="inc-fleat-icon">
                          <img :src="quality.icon" width="13" :alt="quality.name" />
                        </div>
                        {{ quality.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ============================ Property Header Info Start================================== -->

      <!-- ============================ Property Detail Start ================================== -->
      <section class="gray-simple min pt-0">
        <div class="container">
          <div class="row">
            <!-- property main detail -->
            <div class="col-lg-8 col-md-12 col-sm-12">
              <!-- Single Block Wrap -->
              <div class="property_block_wrap style-2">
                <div class="property_block_wrap_header">
                  <a
                    data-bs-toggle="collapse"
                    data-parent="#features"
                    data-bs-target="#clOne"
                    aria-controls="clOne"
                    href="javascript:void(0);"
                    aria-expanded="false"
                    ><h4 class="property_block_title">Detail & Features</h4></a
                  >
                </div>
                <div
                  id="clOne"
                  class="panel-collapse collapse show"
                  aria-labelledby="clOne"
                >
                  <div class="block-body">
                    <ul class="deatil_features">
                      <li v-for="(detail, i) in property.details" :key="`detail-${i}`">
                        <strong>{{ detail.key }}:</strong>
                        {{ detail.value }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Single Block Wrap -->
              <div class="property_block_wrap style-2">
                <div class="property_block_wrap_header">
                  <a
                    data-bs-toggle="collapse"
                    data-parent="#dsrp"
                    data-bs-target="#clTwo"
                    aria-controls="clTwo"
                    href="javascript:void(0);"
                    aria-expanded="true"
                    ><h4 class="property_block_title">Description</h4></a
                  >
                </div>
                <div id="clTwo" class="panel-collapse collapse show">
                  <div class="block-body" v-html="property.description"></div>
                </div>
              </div>

              <!-- Single Block Wrap -->
              <div class="property_block_wrap style-2">
                <div class="property_block_wrap_header">
                  <a
                    data-bs-toggle="collapse"
                    data-parent="#amen"
                    data-bs-target="#clThree"
                    aria-controls="clThree"
                    href="javascript:void(0);"
                    aria-expanded="true"
                    ><h4 class="property_block_title">Ameneties</h4></a
                  >
                </div>

                <div id="clThree" class="panel-collapse collapse show">
                  <div class="block-body">
                    <ul class="avl-features third color">
                      <li v-for="(amenity, i) in property.amenities" :key="`amenity-${i}`">{{ amenity }}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Single Block Wrap -->
              <div class="property_block_wrap style-2" v-if="property.video_url">
                <div class="property_block_wrap_header">
                  <a
                    data-bs-toggle="collapse"
                    data-parent="#vid"
                    data-bs-target="#clFour"
                    aria-controls="clFour"
                    href="javascript:void(0);"
                    aria-expanded="true"
                    class="collapsed"
                    ><h4 class="property_block_title">Property video</h4></a
                  >
                </div>

                <div id="clFour" class="panel-collapse collapse">
                  <div class="block-body">
                    <div class="property_video">
                      <div class="thumb">
                        <img
                          class="pro_img img-fluid w100"
                          :src="property.image"
                          :alt="property.name"
                        />
                        <div class="overlay_icon">
                          <div class="bb-video-box">
                            <div class="bb-video-box-inner">
                              <div class="bb-video-box-innerup">
                                <a
                                  :href="property.video_url"
                                  data-bs-toggle="modal"
                                  data-bs-target="#popup-video"
                                  class="theme-cl"
                                  ><i class="ti-control-play"></i
                                ></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Block Wrap -->
              <div class="property_block_wrap style-2" v-if="property.location">
                <div class="property_block_wrap_header">
                  <a
                    data-bs-toggle="collapse"
                    data-parent="#loca"
                    data-bs-target="#clSix"
                    aria-controls="clSix"
                    href="javascript:void(0);"
                    aria-expanded="true"
                    class="collapsed"
                    ><h4 class="property_block_title">Location</h4></a
                  >
                </div>

                <div id="clSix" class="panel-collapse collapse">
                  <div class="block-body">
                    <div class="map-container">
                      <div
                        id="singleMap"
                        :data-latitude="property.location.lat"
                        :data-longitude="property.location.lng"
                        data-mapTitle="Property Location"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Single Block Wrap -->
              <div class="property_block_wrap style-2">
                <div class="property_block_wrap_header">
                  <a
                    data-bs-toggle="collapse"
                    data-parent="#clSev"
                    data-bs-target="#clSev"
                    aria-controls="clOne"
                    href="javascript:void(0);"
                    aria-expanded="true"
                    class="collapsed"
                    ><h4 class="property_block_title">Gallery</h4></a
                  >
                </div>

                <div id="clSev" class="panel-collapse collapse">
                  <div class="block-body">
                    <ul class="list-gallery-inline">
                      <li v-for="(image, i) in property.images" :key="`gallery-${i}`">
                        <a :href="image" class="mfp-gallery"
                          ><img
                            :src="image"
                            class="img-fluid mx-auto"
                            :alt="property.name"
                        /></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- property Sidebar -->
            <div class="col-lg-4 col-md-12 col-sm-12">
              <!-- Like And Share -->
              <div class="like_share_wrap b-0" v-if="show_contacts">
                <div class="mb-4">
                  <div class="d-grid mb-2">
                    <a href="#" @click.prevent="getContacts('phone_number')" class="btn btn-facebook rounded">
                      <i class="lni lni-phone mr-2"></i> 
                      <span v-if="contacts.phone_number == null">Get Phone Number</span>
                      <span v-if="contacts.phone_number != null">{{ contacts.phone_number }}</span>
                    </a>
                  </div>
                  <div class="d-grid mb-2" v-if="property.contacts.has_whatsapp">
                    <a href="#" @click.prevent="getContacts('whatsapp')" class="btn btn-whatsapp rounded">
                      <i class="lni lni-whatsapp mr-2"></i> 
                      <span v-if="contacts.whatsapp == null">Get Whatsapp Number</span>
                      <span v-if="contacts.whatsapp != null">{{ contacts.whatsapp }}</span>
                    </a>
                  </div>
                  <div class="d-grid mb-2" v-if="property.contacts.has_email">
                    <a href="#" @click.prevent="getContacts('email')" class="btn btn-google rounded">
                      <i class="lni lni-envelope mr-2"></i> 
                      <span v-if="contacts.email == null">Get Email</span>
                      <span v-if="contacts.email != null">{{ contacts.email }}</span>
                    </a>
                  </div>
                </div>
                <ul class="like_share_list">
                  <li>
                    <a
                      href="JavaScript:Void(0);"
                      class="btn btn-likes"
                      data-toggle="tooltip"
                      data-original-title="Share"
                      ><i class="fa fa-share"></i>Share</a
                    >
                  </li>
                  <li>
                    <a
                      href="JavaScript:Void(0);"
                      class="btn btn-likes"
                      data-toggle="tooltip"
                      data-original-title="Save"
                      ><i class="fa fa-heart"></i>Save</a
                    >
                  </li>
                </ul>
              </div>

              <div class="details-sidebar">
                <!-- Agent Detail -->
                <div class="sides-widget">
                  <div class="sides-widget-header">
                    <div class="agent-photo">
                      <img :src="property.posted_by.avatar" alt="" />
                    </div>
                    <div class="sides-widget-details">
                      <h4>
                        <a href="s#">{{ property.posted_by.name }}</a>
                      </h4>
                      <span
                        ><i class="lni lni-envelope"></i>Send Message</span
                      >
                    </div>
                    <div class="clearfix"></div>
                  </div>

                  <form @submit.prevent="sendMessage">
                    <div class="sides-widget-body simple-form">
                      <div class="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Your Email"
                          v-model="message.email"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <label>Phone No.</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Your Phone"
                          v-model="message.phone_number"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <label>Message</label>
                        <textarea class="form-control" v-model="message.message" placeholder="I'm interested in this property." required></textarea
                        >
                      </div>
                      <button class="btn btn-black btn-md rounded full-width">
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ============================ Property Detail End ================================== -->
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'

export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation
  },

  mounted() {
    this.$magnific.set('view-photos', this.property.images)
  },

  data() {
    return {
      hooperSettings: {
        itemsToShow: 2,
        mouseDrag: false,
        touchDrag: false,
        wheelControl: false,
        breakpoints: {
          768: {
            itemsToShow: 1
          },
          1024: {
            itemsToShow: 2
          }
        }
      },
      message: {},
      contacts: {},
      show_contacts: true
    }
  },

  props: ['property'],

  methods: {
    sendMessage() {
      this.$axios.post(`/api/v1/properties/${this.$route.params.slug}/send-message`, this.message).then(() => {
        this.message = {}
      })
    },

    getContacts(type) {
      this.$axios.get(`/api/v1/properties/${this.$route.params.slug}/get-contact?type=${type}`).then(response => {
        this.show_contacts = false
        this.contacts[type] = response.data[type]
        this.show_contacts = true
      })
    },

    openPopup(image) {
      this.$magnific.open(image, this.property.images)
    }
  }
};
</script>
