<template>
  <section class="gray pt-4">
    <div class="container-fluid" v-if="! is_loading">
      <div class="row">
        <div class="col-md-12 mt-3">
          <div class="row">
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <h4>Views</h4>
                  <h2>{{ property.views }}</h2>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <h4>Phone Number Requests</h4>
                  <h2>{{ property.phone_number_hits }}</h2>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <h4>Whatsapp Requests</h4>
                  <h2>{{ property.whatsapp_hits }}</h2>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <h4>Email Requests</h4>
                  <h2>{{ property.email_hits }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 mt-4">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                Property Views
                <small class="text-muted" style="font-size: 16px"
                  >Last 30 Days</small
                >
              </h3>
              <chart :data="property.graph_data" type="line" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="is_loading" />
  </section>
</template>

<script>
import Chart from "@/components/Chart";

export default {
  components: {
    Chart
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      property: {},
      pricing: {},
      is_loading: true
    };
  },

  props: ['slug'],

  methods: {
    fetch() {
      this.is_loading = true

      this.axios
        .get(`/api/v1/my-listings/${this.slug}`)
        .then((response) => {
          this.property = response.data.property;
          this.pricing = response.data.pricing;
          this.is_loading = false
        });
    },
  },
};
</script>
